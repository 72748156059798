import React, { Component } from 'react';
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty, capitalize } from "lodash";
import connect from "react-redux/es/connect/connect";
//import { connect } from 'react-redux';
import { userLogin } from "../../actions/index";
import config from "../../constants/config";

class HeaderLogin extends Component {

    constructor(props) {

        super(props);

        this.state = {
            store_user: this.props.user || {},
            dropdownOpen: false,
            isCollasedOpen: false,
            isLogin: false,
            toggleButton: false,
            language: localStorage.getItem('Language') || 'en',
            loginData: {
            },
            cartItems: 0,
            unreadMessageCount: 0,
            openProfileDropDown: false
        };
        this.saveUserDataInStore = this.saveUserDataInStore.bind(this);
        this.onToggleButton = this.onToggleButton.bind(this);
        this.profileDropDown = this.profileDropDown.bind(this);
        this.toggleProfileDropDown = this.toggleProfileDropDown.bind(this);


    }

    componentDidMount() {

        const user = this.props.user;
        if (!isEmpty(user)) {


        }


    }
    componentWillUnmount() {
        const userId = this.props.user && this.props.user._id;
        if (!isEmpty(userId)) {


        }

    }

    onToggleButton() {
        this.setState({ toggleButton: !this.state.toggleButton });
    }

    static getDerivedStateFromProps(props, state) {
        //console.log('getDerivedStateFromProps from header', props.user, state.store_user)
        if (state.store_user !== props.user) {

            //console.log('inside ')

            return {

                store_user: props.user,

            }
        }

        return null;
    }

    saveUserDataInStore(userData) {
        if (!isEmpty(userData)) {
            this.props.userLogin(userData);
        }

    }

    toggleProfileDropDown() {

        this.setState({ openProfileDropDown: !this.state.openProfileDropDown });

    }

    profileDropDown() {

        return (<ButtonDropdown isOpen={this.state.openProfileDropDown} toggle={this.toggleProfileDropDown}>
            <DropdownToggle color="light">
                <div className="head_profile" onClick={this.profileDropDown}>
                    <span className="user_name">{capitalize((this.props.user.first_name) || '') || this.props.user.mobile || ''}</span> <span className="head_profile_img"><img src={(this.props.user.user_image) || ''} alt="" /></span>
                </div>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem><Link to="/profile">Edit Profile</Link></DropdownItem>
                <DropdownItem><Link to="/password">Manage Password</Link></DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>)

    }


    render() {

        return (
            <div> </div>
        )
    }
}

const mapStateToProps = state => {
    return { user: state.user };
};

const mapDispatchToProps = dispatch => {
    return {
        userLogin: user => dispatch(userLogin(user))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogin);