import React, { Component } from 'react';

import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import Helper from '../../constants/helper';
import Categories from '../Home/Categories';
import Offers from '../Home/Offers';
import RestaurantsList from '../Home/RestaurantList';
import TableRestaurantsList from '../Home/TableResturantList';
import DeliveryProcess from '../Home/DeliveryProcess';
import TableDeliveryProcess from '../Home/TableDeliveryProcess';
import Promotion from '../Home/Promotion';
import Swal from 'sweetalert2';
import apiUrl from '../../constants/apiPath';
import Loader from '../../views/Common/Loader';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import { setTranslations, setDefaultLanguage, withTranslation } from 'react-multi-lang';
import { Helmet } from 'react-helmet'
import en from '../MultLanguage/en.json';


// Do this two lines only when setting up the application
let language = localStorage.getItem('Language') || 'en'



const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

class Home extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.state = {
            leftclass: localStorage.getItem('leftclass') || '',
            rightclass: localStorage.getItem('rightclass') || '',
            visible: false,
            foodmanimg: require("../../static/img/food-man.png").default,
            PromotionData: [], keywords: '', systemIpAddress: '',
            location: {
                lat: "",
                lon: ""
            },
            DefaultData: [], default_Address: {}, userId: '', token: '',
            cust_Currency_Value: '', default_Currency: '',
            toggleAddress: false, address: '', isaddress: false, currencyArray: [],
            language: localStorage.getItem('Language') || 'en',
            foodsection: localStorage.getItem('Foodsection') || "0", addlocation: ""
        }
    }

    componentDidMount() {
        var token = localStorage.getItem('Token');
        var userData = JSON.parse(localStorage.getItem('userData'));
        var location = localStorage.getItem('location');
        console.log('userData', userData);
        this.setState({
            addlocation: location,
            token: token ? token : "",
            userId: userData ? userData._id : "",
            default_Currency: userData ? userData.default_currency : "USD",
        }, () => {

        })
        if (userData) {
            this.getDefaultData(token, userData._id)
        } else {
            // this.getIpAddress();
            // this.getLatLog();
            if (location === null) {
                this.setState({
                    toggleAddress: true
                })
            }

        }
        localStorage.setItem("Foodsection", this.state.foodsection)
        localStorage.removeItem("RestaurantsData")
    }

    scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop);


    getDefaultData = async (token, userid) => {
        let postJson = {
            language: this.state.language, userId: userid
        };

        let path = apiUrl.get_Default_Address;
        const fr = await Helper.post(postJson, path, token);
        const res = await fr.response.json();
        if (fr.status === 200) {
            if (res.success) {
                this.setState({
                    DefaultData: res.result,
                    default_Address: res.result.default_Address
                }, () => {
                    if (Object.keys(this.state.default_Address).length !== 0) {
                        // this.setState({
                        //     location: {
                        //         lat: this.state.default_Address.latitude,
                        //         lon: this.state.default_Address.longitude
                        //     }
                        // }, () => {
                        //     localStorage.setItem('location', JSON.stringify(this.state.location))
                        // })
                    } else {
                        // this.getIpAddress();
                        // this.getLatLog();
                        if (localStorage.getItem('location') === null) {
                            this.setState({
                                toggleAddress: true
                            })
                        }

                    }
                })

            } else {
                // Toast.fire({
                //     type: "error",
                //     title: res.msg,
                // });
            }
        } else {
            // Toast.fire({
            //     type: "error",
            //     title: res.msg,
            // });

        }

    }


    onChange = (e) => {
        this.setState({
            keywords: e.target.value
        })
    }

    getIpAddress = async () => {
        const fr = await Helper.getip('https://api.ipify.org/?format=json');
        const res = await fr.response.json();
        if (fr.status === 200) {
            // var geo = geoip.lookup(res.ip);
            this.setState({
                systemIpAddress: res.ip
            })
            this.getLatLog(res.ip)
        } else {
            Toast.fire({
                type: "error",
                title: res.error,
            });
        }
    }

    getLatLog = (ip) => {
        if (navigator.geolocation && navigator.geolocation.getCurrentPosition) {
            navigator.geolocation.getCurrentPosition((position) => {
                var lat = position.coords.latitude
                var lon = position.coords.longitude
                this.setState({
                    location: {
                        lat: lat,
                        lon: lon
                    }
                }, () => {
                    localStorage.setItem('location', JSON.stringify(this.state.location))
                })
                // save location by sending to server
            }, () => {
                this.getLatLogWithIp(ip);
                // alert("Couldn't get your Location!");               
            });
        }
    }

    getLatLogWithIp = async (ipadd) => {
        const fr = await fetch('http://ip-api.com/json/' + `${ipadd}`);
        const res = await fr.json();
        if (fr.status === 200) {
            var lat = res.lat
            var lon = res.lon
            this.setState({
                location: {
                    lat: lat,
                    lon: lon
                }
            })
            localStorage.setItem('location', JSON.stringify(this.state.location))
        } else {
            Toast.fire({
                type: "error",
                title: res.error,
            });
        }
    }

    handleSelect = address => {
        this.setState({ address: address, isaddress: false, })
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng =>
                this.setState({
                    location: {
                        lat: latLng.lat,
                        lon: latLng.lng
                    }
                }, () => {
                    localStorage.setItem('location', JSON.stringify(this.state.location))
                }),
            )
            .catch(error => console.error('Error', error));
    };

    handleChange = address => {
        this.setState({
            address: address,
            isaddress: true
        });
    };


    render() {
        const { t } = this.props
        return (
            <div>
                <Helmet>
                    <title>{`Speedy Tumtum`}</title>
                </Helmet>
                <Loader visible={this.state.visible} />
                <div className="body_section" style={{ 'minHeight': '937px', 'paddingTop': '138px', 'paddingBottom': '318px' }}>
                    <div className='row'>
                        <div className='col-lg-1'>
                        </div>
                        <div className='col-lg-7'>
                            <section className="home_banner_block">
                                <OwlCarousel className='banner-slider owl-carousel' margin={0} nav items={1} dots={false} autoplay={false}>
                                    <div className="item" style={{ "backgroundImage": `url("../../img/banner.jpg")` }}></div>
                                </OwlCarousel>
                                <div className="banner_data text-center">
                                    <h1 className="banner_title pb-3 pb-md-4">Find your favourite</h1>
                                    <form className="form-inline banner-search position-relative">
                                        <input className="form-control mr-2" type="search" value={this.state.keywords} onChange={this.onChange} placeholder="Search for food or restaurant" />
                                        <Link to={{ pathname: "/searching", state: this.state.keywords }} className="btn btn-search">Search</Link>
                                        <span className="search-icon"><i className="fa fa-search" aria-hidden="true"></i></span>
                                    </form>
                                </div>
                            </section>
                        </div>
                        <div className='col-lg-3'>
                            <section className="home_banner_block">
                                <OwlCarousel className='banner-slider owl-carousel' margin={0} nav items={1} dots={true} autoplay={true} loop={true} allowTransparency={true}>
                                    <Link to={{ pathname: "/searching", state: this.state.keywords }} className="item" style={{ "backgroundImage": `url("../../img/banner.jpg")` }}></Link>
                                    <Link to={{ pathname: "/searching", state: this.state.keywords }} className="item" style={{ "backgroundImage": `url("../../img/banner.jpg")` }}></Link>
                                </OwlCarousel>
                            </section>
                        </div>
                    </div>


                    <Categories />

                    <div>
                        <RestaurantsList prerestaurantId={localStorage.getItem("restaurantId")} />
                    </div>

                    <section className="how-work-features">
                        <div className="container">
                            <h2 className="sec-title text-center mb-0 pb-3 pb-md-4">How it Works?</h2>
                            <div className="row align-items-center">
                                <div className="col-md-3">
                                    <div className="how-work-content text-center">
                                        <div className="how-work-img"><img src={require("../../static/img/resturant.png").default} alt="resturant" /></div>
                                        <div className="how-work-info">
                                            <h5 className="title m-0 py-1 py-md-3">Find Restaurants</h5>
                                            <p className="m-0">Find nearest restaurants with easy to search filters</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="how-work-content text-center">
                                        <div className="how-work-img"><img src={require("../../static/img/discount.png").default} alt="resturant" /></div>
                                        <div className="how-work-info">
                                            <h5 className="title m-0 py-1 py-md-3">Avail Discounts</h5>
                                            <p className="m-0">Add meals/dishes in your cart at discounted prices</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="how-work-content text-center">
                                        <div className="how-work-img"><img src={require("../../static/img/payments.png").default} alt="resturant" /></div>
                                        <div className="how-work-info">
                                            <h5 className="title m-0 py-1 py-md-3">Payments</h5>
                                            <p className="m-0">Pay by Cash On Delivery or Online</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="how-work-content text-center">
                                        <div className="how-work-img"><img src={require("../../static/img/enjoy.png").default} alt="resturant" /></div>
                                        <div className="how-work-info">
                                            <h5 className="title m-0 py-1 py-md-3">Enjoy Food!</h5>
                                            <p className="m-0">Live status and receive notification</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                </div>

                {/* Modal */}
                <div className={this.state.toggleAddress === false ? "modal homeAddressMOdal Customization_modal fade locationmodule " : "modal homeAddressMOdal Customization_modal fade locationmodule show modalOpenBox location-popup"} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            {this.state.addlocation && <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    this.setState({
                                        toggleAddress: false,
                                    })
                                }}><span aria-hidden="true">&times;</span>
                                </button>
                            </div>}
                            <div className="modal-body mapOuter addressOuterMap" >
                                <div className="modalBoxContent">
                                    <a href=""> <img src={require("../../static/img/logo.png").default} alt="" /></a>
                                    <h3>{t("tbl.Food Delivery best restaurants near you")}</h3>
                                    <h3> {t("tbl.Onow")}</h3>
                                </div>
                                <div className="flexOuterMain">
                                    <PlacesAutocomplete
                                        searchOptions={{
                                            componentRestrictions:{
                                                country: ['ke'],
                                            }
                                        }}
                                        value={this.state.address}
                                        onChange={this.handleChange}
                                        onSelect={this.handleSelect} >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div className="location-form">
                                                <input
                                                    {...getInputProps({
                                                        placeholder: t('home.Search Your Places'),
                                                        className: 'location-search-input form-control',
                                                    })}
                                                />
                                                <div className="autocomplete-dropdown-container">
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map(suggestion => {
                                                        const className = suggestion.active
                                                            ? 'suggestion-item--active'
                                                            : 'suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                    style,
                                                                })}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}

                                                </div>
                                                <div className="centerBtn text-center">
                                                    <button className="btn" onClick={(e) => {
                                                        if (this.state.address === '') {
                                                            this.setState({
                                                                isaddress: true
                                                            })
                                                        } else {
                                                            this.setState({
                                                                toggleAddress: false
                                                            })
                                                            window.location.reload()
                                                        }

                                                    }}>{t("home.Submit")}</button>
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </div>
                                {this.state.isaddress === true && <span className="text-danger">{t("home.Please enter address")}</span>}
                                {/* <iframe src={`https://www.google.com/maps/embed/v1/place?key=${apiUrl.google_Api_Key}&q=${this.state.lat},${this.state.lon}`} width="600" height="450" frameBorder="0" style={{ border: 0 }} allowFullScreen=""></iframe> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.toggleAddress === true ? "modal-backdrop fade show" : ""} onClick={(e) => {
                    e.preventDefault();
                    this.setState({ toggleAddress: false })
                }}></div>

                <span title={t("resd.Change Address")} className="locationMapicon" onClick={(e) => {
                    this.setState({
                        toggleAddress: true
                    })
                }}><i className="fas fa-map-marker-alt"></i></span>


            </div>
        );
    }
}



export default withTranslation(Home);