import React, { Component } from 'react';
import ContentLoader from "react-content-loader";
const ComponentLoader = props => (
    <ContentLoader
        height={160}
        width={400}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <circle cx="10" cy="20" r="8" />
        <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="50" r="8" />
        <rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="80" r="8" />
        <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="110" r="8" />
        <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
        <rect x="260.64" y="11.67" rx="0" ry="0" width="130" height="103" />
    </ContentLoader>
)

class Product_loader extends Component {
    render() {
        return (
            <div>
                <ComponentLoader />
            </div>
        );
    }
}

export default Product_loader;