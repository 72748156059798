import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Input, Button, ModalBody, Modal } from 'reactstrap';
import { isEmpty, isInteger } from 'lodash';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";


class AboutUs extends Component {

    constructor(props){

        super(props);
        

    }

    render() {

        return (
        
                <div className="body_section" style={{'minHeight': '937px','paddingTop': '138px', 'paddingBottom': '370px'}}>
            
            
             <section className="breadcrumbs py-4 border-bottom">
                            <div className="container">
                                <ol className="breadcrumb bg-white p-0 m-0">
                                    <li className="breadcrumb-item"><Link to="/"><i className="fa fa-home" aria-hidden="true"></i></Link></li>
                                    <li className="breadcrumb-item active">About Us</li>
                                </ol>
                            </div>
                        </section>

            
            
              <section class="content_block py-4">
            
            
            
                  <div className="container">
            
            
                  <h1 className="inner_title pb-4 text-left">About Us</h1>
                  <p>Welcome to our website. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                  <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham. </p>
                  <ul>
                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                    <li>Fusce cursus magna ac nisi elementum, eu tempus est commodo.</li>
                    <li>Nullam et mauris vitae mauris feugiat aliquet.</li>
                    <li>Aliquam feugiat nibh id ligula laoreet consectetur.</li>
                    <li>Suspendisse facilisis ligula ac enim pretium volutpat.</li>
                    <li>Morbi efficitur tellus a erat posuere, et ullamcorper metus vulputate.</li>
                    <li>Fusce et ante volutpat, dignissim urna ac, ullamcorper ipsum.</li>
                    <li>Aliquam lacinia enim sollicitudin, volutpat nisl sit amet, fringilla ligula.</li>
                    <li>Nulla facilisis lectus vel nibh efficitur, eget efficitur ligula porta.</li>

                  </ul>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eleifend, enim semper dapibus maximus, leo tortor auctor lacus, at pulvinar tellus quam fringilla enim. Praesent eu maximus nisi, quis auctor lorem. Cras pellentesque ullamcorper magna quis rhoncus. Sed semper sit amet neque quis interdum. Suspendisse iaculis nibh ex, at pulvinar nulla condimentum quis. Nam tincidunt imperdiet nibh, id suscipit purus posuere nec. Morbi vel finibus justo.</p>
                  <p>Fusce a ante fermentum, convallis nisi vitae, gravida augue. Vivamus tincidunt imperdiet leo non dapibus. Curabitur ut ante libero. Maecenas vel orci consectetur, convallis ex vitae, iaculis elit. Suspendisse egestas suscipit arcu, vitae accumsan quam bibendum in. Ut venenatis tempor nulla, sit amet sagittis eros lobortis sit amet. Sed ut est ante. Aliquam scelerisque vel ipsum non ullamcorper. Proin dictum fermentum dui quis molestie. Vivamus at </p>
                </div>
            
            </section>
                </div>
              
        )
    }
}


export default AboutUs;

