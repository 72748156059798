import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { isEmpty } from "lodash";
import { Redirect } from 'react-router';

import routes from '../../routes';
import Header from '../../views/Common/Header';
import HeaderLogin from '../../views/Common/HeaderLogin';
import Footer from '../../views/Common/Footer';
import Home from '../../views/Home/Home';
import UserSidebar from '../../views/Common/UserSiderbar';
import Swal from 'sweetalert2';
import { setTranslations, setDefaultLanguage, withTranslation } from 'react-multi-lang';
import en from '../../views/MultLanguage/en.json';

// Do this two lines only when setting up the application
let language = localStorage.getItem('Language') || 'en'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000
});

class DefaultLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pathName: (this.props.location && this.props.location.pathname) || '',
      islogin: localStorage.getItem('IsLogin')
    };
  }

  static getDerivedStateFromProps(prop, state) {
    if ((prop.location && prop.location.pathname) !== state.pathName) {
      return {
        pathName: prop.location.pathname
      };
    } else {
      return null;
    }
  }

  signOut = (e) => {
    Swal.fire({
      title: this.props.t('profile.logmess'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: this.props.t('res.Yes'),
      cancelButtonText: this.props.t('res.No'),
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        let language = localStorage.getItem('Language')
        let location = localStorage.getItem('location')
        localStorage.clear();
        localStorage.setItem('Language', language)
        localStorage.setItem('location', location)
        this.props.history.push('/login');
        Toast.fire({
          type: "success",
          title: "successfully Logout",
        })
      }
    })
  }

  render() {

    const pathName = (this.props.location && this.props.location.pathname) || '';
    const isLogin = !isEmpty(this.props.user) || false;
    //console.log(this.props.user, isLogin, pathName);
    return (
      <>
      
      <div className={(pathName === '/login' || pathName === '/register' || pathName === '/list_your_business' || pathName === '/user-account') ?  'login-page' : ''} >
      <div className="full_container">
        {(pathName !== '/login' && pathName !== '/register' && pathName !== '/list_your_business' && pathName !== '/user-account') ? <Header onLogout={e => this.signOut(e)} /> : <HeaderLogin />}
        {isLogin && pathName === '/' && <Redirect to="/home" />}
        {(pathName === '/') && <Home />}
        {(pathName !== '/') && <div className="main">
          {!isEmpty(this.props.user) && <UserSidebar />}
          <Switch>
            {!isEmpty(this.props.user) && (pathName.indexOf('/more') < 0) ? <Redirect to="/" /> :
              routes.map((route, idx) => {
                return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                  <route.component {...props} />
                )} />)
                  : (null);
              },
              )}
            {this.state.islogin === "true" ? <Redirect to="/home" /> : <Redirect to="/" />}
          </Switch>
        </div>}
        {(pathName !== '/login' && pathName !== '/register' && pathName !== '/list_your_business' && pathName !== '/user-account') && <Footer /> }
      </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return { user: state.user };
};

export default withTranslation(DefaultLayout);
