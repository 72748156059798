import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import apiUrl from '../../constants/apiPath';
import Helper from '../../constants/helper';
import Swal from 'sweetalert2';
import { setTranslations, setDefaultLanguage, withTranslation } from 'react-multi-lang';
import tr from '../MultLanguage/tr.json';
import en from '../MultLanguage/en.json';
import de from '../MultLanguage/de.json';
import es from '../MultLanguage/es.json';
import fr from '../MultLanguage/fr.json';
import lt from '../MultLanguage/lt.json';
import ru from '../MultLanguage/ru.json';

// Do this two lines only when setting up the application
let language = localStorage.getItem('Language') || 'en'
switch (localStorage.getItem('Language') || 'en') {
    case "tr":
        setTranslations({ tr })
        setDefaultLanguage(language.toString())
        break;
    case "fr":
        setTranslations({ fr })
        setDefaultLanguage(language.toString())
        break;
    case "es":
        setTranslations({ es })
        setDefaultLanguage(language.toString())
        break;
    case "ru":
        setTranslations({ ru })
        setDefaultLanguage(language.toString())
        break;
    case "lt":
        setTranslations({ lt })
        setDefaultLanguage(language.toString())
        break;
    case "de":
        setTranslations({ de })
        setDefaultLanguage(language.toString())
        break;
    default:
        setTranslations({ en })
        setDefaultLanguage('en')

}

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

class Promotion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foodmanimg: require("../../static/img/food-man.png").default,
            PromotionData: [],
            language: localStorage.getItem('Language') || 'en',
            responsive: {
                0: {
                    items: 1,
                },
                767: {
                    items: 2,
                },
                991: {
                    items: 2,
                },
            },
        }
    }
    componentDidMount() {
        this.setState({
        })
        this.getPromotion();

    }


    getPromotion = async () => {
        let postJson = {
            language: this.state.language,
        };
        let path = apiUrl.get_Promotion;
        const fr = await Helper.post(postJson, path);
        const res = await fr.response.json();
        if (fr.status === 200) {
            if (res.success) {
                this.setState({
                    PromotionData: res.result
                })
            } else {
                // Toast.fire({
                //     type: "error",
                //     title: res.msg,
                // });
            }
        } else {
            // Toast.fire({
            //     type: "error",
            //     title: res.error,
            // });

        }
    }



    render() {
        const { t } = this.props
        const language = this.state
        return (
            <div>
                <section className="best-food">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {this.state.PromotionData.length > 0 &&
                                    <OwlCarousel className="owl-theme" loop margin={0} nav items={2} dots={false} id="food-add" autoplay={true} responsive={this.state.responsive}>
                                        {this.state.PromotionData.length > 0 && this.state.PromotionData.map((item, key) => {
                                            return (
                                                <div key={key} className="item">
                                                    <div className="add_img">
                                                        <a href="javascript:Void(0);">
                                                            <img src={item.photo}  onError={(e) => { e.target.onerror = null; e.target.src = require("../../static/img/add-product-1.png").default }} alt="" height="200px"  />
                                                            {/* <img src={require("../../static/img/add-product-1.png").default} alt="" /> */}
                                                            {/* <img src={item.photo}  alt=""  /> */}
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </OwlCarousel>}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default withTranslation(Promotion);