import React, { Component } from 'react';


class Loader extends Component{

    render(){
        return(
            <div>
                {this.props.visible === true &&
                <div className="overlay_loader">
                    <div className="overlay_div">
                        <img src={require("../../../src/static/img/logo-loader.png").default} className="loader_img"/>
                        {/* <span>{this.props.loading_text}</span> */}
                    </div>
                </div>}

            </div>
        );
    }
}

export default Loader;