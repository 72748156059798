import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import apiUrl from '../../constants/apiPath';
import Helper from '../../constants/helper';
import Swal from 'sweetalert2';
import { setTranslations, setDefaultLanguage, withTranslation } from 'react-multi-lang';
import tr from '../MultLanguage/tr.json';
import en from '../MultLanguage/en.json';
import de from '../MultLanguage/de.json';
import es from '../MultLanguage/es.json';
import fr from '../MultLanguage/fr.json';
import lt from '../MultLanguage/lt.json';
import ru from '../MultLanguage/ru.json';

// Do this two lines only when setting up the application
let language = localStorage.getItem('Language') || 'en'
switch (localStorage.getItem('Language') || 'en') {
    case "tr":
        setTranslations({ tr })
        setDefaultLanguage(language.toString())
        break;
    case "fr":
        setTranslations({ fr })
        setDefaultLanguage(language.toString())
        break;
    case "es":
        setTranslations({ es })
        setDefaultLanguage(language.toString())
        break;
    case "ru":
        setTranslations({ ru })
        setDefaultLanguage(language.toString())
        break;
    case "lt":
        setTranslations({ lt })
        setDefaultLanguage(language.toString())
        break;
    case "de":
        setTranslations({ de })
        setDefaultLanguage(language.toString())
        break;
    default:
        setTranslations({ en })
        setDefaultLanguage('en')

}


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

class Offers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foodmanimg: require("../../static/img/food-man.png").default,
            CategoriesData: [],
            language: localStorage.getItem('Language') || 'en',
            foodsection: localStorage.getItem('Foodsection') || "0",
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                900: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
                1200: {
                    items: 4,
                },
            },
        }
    }

    componentDidMount() {
        this.setState({
        })
        this.getData();

    }

    getData = async () => {
        let postJson = { language: this.state.language };
        let path = apiUrl.get_Cuisine;
        const fr = await Helper.post(postJson, path);
        const res = await fr.response.json();
        if (fr.status === 200) {
            if (res.success) {
                this.setState({
                    CategoriesData: res.result.cuisine
                })
            } else {
                Toast.fire({
                    type: "error",
                    title: res.msg,
                });
            }
        } else {
            Toast.fire({
                type: "error",
                title: res.error,
            });

        }
    }


    render() {
        const { t } = this.props
        const { language, foodsection } = this.state
        return (
            <section className="latest-sec ">
                <div className="container">
                    <div className="pb-lg-4">
                        <h2 className="sec-title mb-0 pb-3 pb-md-4">Latest <span className="red-text">Offers</span></h2>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation(Offers);