// src/js/actions/index.js
import { USER_LOGIN, USER_LOGOUT, LSP_LIST, CHAT_LIST } from "../constants/action-types";
const userLogin = userInfo => ({ type: USER_LOGIN, payload: userInfo });
const userLogout = () => ({ type: USER_LOGOUT, payload: undefined });
const lspList = (lspDataList) => ({ type: LSP_LIST, payload: lspDataList });
const chatList = (chatDataList) => ({ type: CHAT_LIST, payload: chatDataList });

export {

    userLogin,
    userLogout,
    lspList,
    chatList

};