import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';
import ComponentLoader from './constants/component_loader';

function Loading() {
    return (
        <div className={"container"}>
            <section className={"inner_slider box_img"}>
                <ComponentLoader />
            </section>
        </div>
    );
}

const Login = Loadable({
    loader: () => import('./views/Pages/Login/Login'),
    loading: Loading,
});

const Register = Loadable({
    loader: () => import('./views/Pages/Register/Register'),
    loading: Loading,
});

const Home = Loadable({
    loader: () => import('./views/Home/Home'),
    loading: Loading,
});

const RestaurantDetails = Loadable({
    loader: () => import('./views/Pages/Restaurant Details/RestaurantDetails'),
    loading: Loading,
});

const Checkout = Loadable({
    loader: () => import('./views/Pages/Restaurant Details/Checkout'),
    loading: Loading,
});

const Orderplaced = Loadable({
    loader: () => import('./views/Pages/Restaurant Details/Orderplaced'),
    loading: Loading,
});

const TermsConditions = Loadable({
    loader: () => import('./views/Pages/Login/Termsconditions'),
    loading: Loading,
});

const PrivacyPolicy = Loadable({
    loader: () => import('./views/Pages/Login/Privacypolicy'),
    loading: Loading,
});

const ContactUs = Loadable({
    loader: () => import('./views/Pages/ContactUs/Contact'),
    loading: Loading,
});

const Searching = Loadable({
    loader: () => import('./views/Pages/Restaurant Details/Searching'),
    loading: Loading,
});

const Profile = Loadable({
    loader: () => import('./views/Pages/Profile/Profile'),
    loading: Loading,
});

const OrderDetails = Loadable({
    loader: () => import('./views/Pages/Order/Orderdetails'),
    loading: Loading,
});

const MyBasket = Loadable({
    loader: () => import('./views/Pages/Restaurant Details/Mybasket'),
    loading: Loading,
});

const Faq = Loadable({
    loader: () => import('./views/Pages/Login/Faq'),
    loading: Loading,
});

const PromotionTerms = Loadable({
    loader: () => import('./views/Pages/Login/PromotionTerms'),
    loading: Loading,
});

const UserAgreement = Loadable({
    loader: () => import('./views/Pages/Login/UserAgreement'),
    loading: Loading,
});

const DeliveryReturnPolicy = Loadable({
    loader: () => import('./views/Pages/Login/DeliveryReturnPolicy'),
    loading: Loading,
});

const PartnershipRestaurants = Loadable({
    loader: () => import('./views/Pages/Login/PartnershipRestaurants'),
    loading: Loading,
});

const Help = Loadable({
    loader: () => import('./views/Pages/Login/Help'),
    loading: Loading,
});

const About = Loadable({
    loader: () => import('./views/Pages/Login/About'),
    loading: Loading,
});


const HowWorks = Loadable({
    loader: () => import('./views/Pages/Login/HowWorks'),
    loading: Loading,
});

const ListYourBusiness = Loadable({
    loader: () => import('./views/Pages/Login/ListYourBusiness'),
    loading: Loading,
});

const Promotions = Loadable({
    loader: () => import('./views/Pages/Login/Promotions'),
    loading: Loading,
});

const LatestNewsUpdates = Loadable({
    loader: () => import('./views/Pages/Login/LatestNewsUpdates'),
    loading: Loading,
});

const UserChangePassword = Loadable({
    loader: () => import('./views/Pages/Forgot/ForgotChangePassword'),
    loading: Loading,
});

/*
const Subscription = Loadable({
    loader: () => import('./views/Pages/Subscription/Subscription'),
    loading: Loading,
});*/

const routes = [
    { path: '/', name: 'Default', component: DefaultLayout },
    { path: '/login', name: 'Login', component: Login },
    { path: '/user-account', name: 'Password Reset Page', component: UserChangePassword },
    { path: '/home', name: 'Home', component: Home },
    { path: '/register', name: 'Register', component: Register },
    { path: '/restaurant_details/:id', name: 'Restaurant Details', component: RestaurantDetails },
    { path: '/checkout/:id', name: 'Checkout', component: Checkout },
    { path: '/order-placed/:id', name: 'Order Placed', component: Orderplaced },
    { path: '/privacy-policy', name: 'Privacy Policy', component: PrivacyPolicy },
    { path: '/contact', name: 'Contact', component: ContactUs },
    { path: '/searching', name: 'Searching', component: Searching },
    { path: '/profile', name: 'Profile', component: Profile },
    { path: '/order-details/:id', name: 'OrderDetails', component: OrderDetails },
    { path: '/my-basket', name: 'MyBasket', component: MyBasket },
    { path: '/terms-conditions', name: 'Terms Conditions', component: TermsConditions },
    { path: '/faq', name: 'Frequently Asked Questions', component: Faq },
    { path: '/promotion_terms', name: 'Promotion Terms', component: PromotionTerms },
    { path: '/user_agreement', name: 'User Agreement', component: UserAgreement },
    { path: '/delivery_return_policy', name: 'Delivery Return Policy', component: DeliveryReturnPolicy },
    { path: '/partnership_restaurants', name: 'Partnership Restaurants', component: PartnershipRestaurants },
    { path: '/help', name: 'Help', component: Help },
    { path: '/about', name: 'About', component: About },
    { path: '/how_it_works', name: 'How It Works', component: HowWorks },
    { path: '/list_your_business', name: 'List Your Business', component: ListYourBusiness },
    { path: '/promotions', name: 'Promotions', component: Promotions },
    { path: '/latest_news_updates', name: 'Latest News Updates', component: LatestNewsUpdates },
    { path: '/latest_news_updates', name: 'Latest News Updates', component: LatestNewsUpdates },

    /*{ path: '/subscription', name: 'Subscription', component: Subscription },*/
]

export default routes;