let apiUrl = {

    //Google api key
    google_Api_Key: 'AIzaSyCipQRHyKIpBtpL32GVguoCh8ChSpXhSQQ',

    user_Login: '/api/login/',
    get_Otp: '/api/generate-otp',
    user_Resgister: '/api/register/',
    social_login: '/api/social-login/',
    forgot_Password: '/api/forgot-password/',
    get_Food_Categories: '/api/categories-list/',
    get_Promotion: '/api/offers-list',
    get_Restaurants: '/api/restaurants-list/',
    get_Restaurants_Details: '/api/restaurants-details/',
    get_Similar_Restaurant: '/api/cuisine-similar-restaurants',
    get_Menu_Detail: '/api/restaurant-menu-details',
    get_Cuisine: '/api/filter-items',
    update_Profile: '/api/update-profile',
    get_Current_Previous_Order: '/api/orders-list',
    // get_Favourite: '/api/favourites-list',
    cancle_Order: '/api/cancel-order',
    get_Order_Details: '/api/order-details',
    get_Countries_List: '/api/countries-list',
    defaultCurrencyUpdate: '/api/defaultCurrencyUpdate',
    get_profile: '/api/get-profile',

    get_Restaurants_Details_ById: '/api/restaurants-details-by-id',

    get_delivery_fee: '/api/restaurants-distance',

    //Check Out
    apply_Coupon: '/api/check-offer',
    check_Out_Food: '/api/checkout-food',
    check_Out_Food_Online: '/api/checkout-food-online',

    //Cart
    add_Cart: '/api/add-to-cart',
    get_Cart: '/api/get-cart',
    update_Cart: '/api/update-cart',
    delete_Cart: '/api/delete-cart',

    //Address
    get_Default_Address: '/api/default-data',
    get_My_Address: '/api/my-address',
    add_Address: '/api/add-address',
    delete_Address: '/api/delete-address',
    update_Address: '/api/update-address',
    choose_Default_Address: '/api/default-address',
    driver_Location: '/api/driver-location',

    // Static Page
    get_Static_Pages: '/api/static-pages',

    // Favourite
    get_Favourite: '/api/favourites-list',
    add_Favourite: '/api/add-favourites',

    //Notifications
    get_Notifications: '/api/notifications-list',
    delete_All_Notification: '/api/delete-notification',

    //Currency
    get_Currency_Value: '/api/currency-data',

    //rating and reveiw
    add_Rating_Commant: '/api/add-ratings',
    get_Review_list: '/api/ratings-list',

    //Change Pass
    change_Pass: "/api/change-password",
    forgot_ChangePassword: '/api/userResetPassword',

    //Chang language
    change_Language: '/api/defaultLanuageUpdate',

    //Table
    get_Table_ResturantsList: '/api/table-reservation-restaurants/',
    get_Service_Type: '/api/table-reservation-services/',
    get_TableRestaurants_Details_ById: '/api/table-reservation-restaurant-detail-by-id/',
    add_Tbl_Booking: '/api/table-booking/',
    get_Rreservation_Deatils: '/api/table-reservation-detail/',
    cancle_Reservation: '/api/cancel-reservation/',
    reservation_rember: '/api/update-remember-me',
    edit_Tbl: '/api/update-reservation/',
    get_Reservation_List:'/api/table-reservations-list/'


}
export default apiUrl;