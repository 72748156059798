export default {
    items: [
        {
            name: 'Messages',
            url: '/messages',
            icon: 'icon_messages'
        },
        {
            name: 'My Vehicles',
            url: '/vehicles',
            icon: 'icon_vehicles',
        },
        {
            name: 'Find a Collision Center',
            url: '/home',
            icon: 'icon_home'
        },
        {
            name: 'Active Repairs',
            url: '/active-repairs',
            icon: 'icon_active_jobs'
        },
        {
            name: 'Subscription',
            url: '/subscription',
            icon: 'icon_subscription',
        },
        /* {
            name: 'Notifications',
            url: '/notifications',
            icon: 'icon_notifications',
            disabled: true
        }, */
        /* {
            name: 'Settings',
            url: '/settings',
            icon: 'icon_settings',
        }, */
        {
            name: 'My Profile',
            url: '/profile',
            icon: 'icon_profile'
        },
        {
            name: 'Logout',
            url: '/logout',
            icon: 'icon_logout'
        }
    ],
};
