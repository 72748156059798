import React, { Component } from 'react';
import { setTranslations, setDefaultLanguage, withTranslation } from 'react-multi-lang';
import en from '../MultLanguage/en.json';
import { Link } from 'react-router-dom';
import { Icon } from 'react-fa'

// Do this two lines only when setting up the application
let language = localStorage.getItem('Language') || 'en'

class Footer extends Component {
   constructor(props) {
      super(props);
      this.state = {
         foodsection: localStorage.getItem('Foodsection') || "0",
      }
   }
   render() {
      const { t } = this.props
      const { foodsection } = this.state
      return (
         <footer>
            <div className="container">
               <div className="row  space">
                  <div className="col-6 col-md-3">
                     <div className="footer-title pb-2 pb-md-3">Company</div>
                     <ul className="footer-menu pb-3 pb-md-0">
                        <li className="pb-1"><Link to="/about">About Us</Link></li>
                        <li className="pb-1"><Link to="/privacy-policy">Privacy Policy</Link></li>
                        <li className="pb-1"><Link to="/delivery_return_policy">Delivery Terms</Link></li>
                        <li className="pb-1"><Link to="/terms-conditions">Terms and conditions</Link></li>
                     </ul>
                  </div>
                  <div className="col-6 col-md-3">
                     <div className="footer-col">
                        <div className="footer-title pb-2 pb-md-3">Let Us Help You</div>
                        <ul className="footer-menu pb-3 pb-md-0">
                           <li className="pb-1"><Link to="/help">Help</Link></li>
                           <li className="pb-1"><Link to="/contact">Contact us</Link></li>
                           <li className="pb-1"><Link to="/how_it_works">How it works</Link></li>
                           <li className="pb-1"><Link to="/faq">FAQ</Link></li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-6 col-md-3 d-md-flex flex-wrap justify-content-end">
                     <div>
                        <div className="footer-title pb-2 pb-md-3 w-100">What’s Hot</div>
                        <ul className="footer-menu ">
                           <li className="pb-1"><Link to="/list_your_business">List your Business</Link></li>
                           <li className="pb-1"><Link to="/promotions">Promotions</Link></li>
                           <li className="pb-1"><Link to="/latest_news_updates">Latest News/Updates</Link></li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-6 col-md-3">
                     <div className="footer-title pb-2 pb-md-3 w-100 text-md-right">Join Us </div>
                     <ul className="social-link d-flex justify-content-md-end w-100 pb-3">
                        <li className="pr-2"><a href="https://m.facebook.com/story.php?story_fbid=371184884485342&id=103720294565137"><Icon name="facebook" /></a></li>

                        <li className="pr-2"><a href="https://instagram.com/speedy_tum_tum?utm_medium=copy_link"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>

                     </ul>

                     <div className="footer-logo text-md-right ml-auto" style={{ paddingBottom: '12px' }}><img src={require("../../static/img/logo.png").default} alt="speedy" /></div>
                     <span className="Service-text" style={{ left: '0px' }}>Convenient and efficient service</span>
                  </div>
               </div>
            </div>
            <div className="copyright text-center">
               <div className="container"> ©{new Date().getFullYear()}  Speedy Tum Tum . All rights Reserved. </div>
            </div>
         </footer>
      )
   }
}

export default withTranslation(Footer);