import React, { Component } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty, capitalize } from "lodash";
import connect from "react-redux/es/connect/connect";
import { userLogin } from "../../actions/index";
import config from "../../constants/config";
import Geocode from "react-geocode";
import Swal from 'sweetalert2';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import { setTranslations, setDefaultLanguage, withTranslation } from 'react-multi-lang';
import apiUrl from '../../constants/apiPath';
import Helper from '../../constants/helper';
import en from '../MultLanguage/en.json';
import 'react-sticky-header/styles.css';
import StickyHeader from 'react-sticky-header';

import soundfile from '../../img/restaurantOrder.mp3';
const audio = new Audio(soundfile);

// Do this two lines only when setting up the application
let language = localStorage.getItem('Language') || 'en'


const mapStateToProps = state => {
    return { user: state.user };
};

const mapDispatchToProps = dispatch => {
    return {
        userLogin: user => dispatch(userLogin(user))
    };
};

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});


class Header extends Component {

    constructor(props) {

        super(props);

        this.state = {
            store_user: this.props.user || {},
            dropdownOpen: false,
            isCollasedOpen: false,
            toggleButton: false,
            loginData: {
            },
            cartItems: 0,
            unreadMessageCount: 0,
            openProfileDropDown: false,
            currency: '',
            language: localStorage.getItem('Language') || 'en',
            country: '',
            foodmanimg: require("../../static/img/logoOne.png").default,
            toggleAddress: false,
            address: '',
            location: '',
            userId: '',
            token: '',
            isTop: true, scrollPosition: "0",
            lat: localStorage.getItem('location') && JSON.parse(localStorage.getItem('location')).lat,
            lon: localStorage.getItem('location') && JSON.parse(localStorage.getItem('location')).lon,
        };

        this.isLogin = localStorage.getItem('IsLogin');
        console.log(this.isLogin, 'isLogin')

        this.saveUserDataInStore = this.saveUserDataInStore.bind(this);
        this.onToggleButton = this.onToggleButton.bind(this);
        this.profileDropDown = this.profileDropDown.bind(this);
        this.toggleProfileDropDown = this.toggleProfileDropDown.bind(this);

        // console.log(this.state.isLogin)
    }

    closePopup = event => {
        // console.log(event.currentTarget);
        var cT = event.currentTarget;
        cT.parentNode.remove();
    }

    componentDidMount() {
        const user = this.props.user;
        let lat = localStorage.getItem('location') && JSON.parse(localStorage.getItem('location')).lat || '';
        let lon = localStorage.getItem('location') && JSON.parse(localStorage.getItem('location')).lon || '';
        var userData = JSON.parse(localStorage.getItem('userData'));
        var token = localStorage.getItem('Token');
        this.setState({
            token: token ? token : "",
            lat: lat,
            lon: lon,
            userId: userData ? userData._id : "",
        }, () => {
            Geocode.setApiKey(apiUrl.google_Api_Key);
            Geocode.setLanguage("en");
            // Get address from latidude & longitude.
            Geocode.fromLatLng(this.state.lat, this.state.lon).then(
                response => {

                    const location = response.results[0].address_components.filter((item) => {
                        return (item.types.includes("sublocality_level_1") || item.types.includes("administrative_area_level_2") || item.types.includes("administrative_area_level_1"))
                    }).map(item => (" " + item.short_name));


                    console.log("response.results[0]", location.join(", "))

                    const address = response.results[0].formatted_address;
                    this.setState({
                        address: address,
                        location: location.toString()
                    })

                },
                error => {
                    console.error("...", error);
                }
            );
        });
        if (!isEmpty(userData)) {

        }
        // window.onscroll = () => {
        //     var d = document.documentElement;
        //     var offset = d.scrollTop + window.innerHeight;
        //     var height = d.offsetHeight;
        //     if (offset >= height) {
        //         this.setState({ isTop: false })
        //     } else {
        //         this.setState({ isTop: true })
        //     }
        // }
    }

    componentWillUnmount() {
        if (!isEmpty(this.state.userId)) {
        }
        this.setState({ isTop: true, })
    }


    onToggleButton() {
        var tog = this.state.toggleButton == true ? false : true
        this.setState({ toggleButton: tog });
    }

    static getDerivedStateFromProps(props, state) {
        if (state.store_user !== props.user) {
            return {
                store_user: props.user,
            }
        }
        return null;
    }

    saveUserDataInStore(userData) {
        if (!isEmpty(userData)) {
            this.props.userLogin(userData);
        }
    }

    toggleProfileDropDown() {
        this.setState({ openProfileDropDown: !this.state.openProfileDropDown });
    }

    profileDropDown() {
        return (<ButtonDropdown isOpen={this.state.openProfileDropDown} toggle={this.toggleProfileDropDown}>
            <DropdownToggle color="light">
                <div className="head_profile" onClick={this.profileDropDown}>
                    <span className="user_name">{capitalize((this.props.user.first_name) || '') || this.props.user.mobile || ''}</span> <span className="head_profile_img"><img src={(this.props.user.user_image) || ''} alt="" /></span>
                </div>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem><Link to="/profile">Edit Profile</Link></DropdownItem>
                <DropdownItem><Link to="/password">Manage Password</Link></DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>)
    }

    toggleAddress = () => {
        this.setState({ toggleAddress: !this.state.toggleAddress });
    }

    scrollToggle = () => {
        if (this.state.isTop) {
            window.scroll(0, document.body.scrollHeight)
        }
        else {
            window.scrollTo(0, 0)
        }
        this.setState({ isTop: !this.state.isTop })

    }

    updateLanguage = async () => {
        let postJson = {
            language: this.state.language, user_id: this.state.userId
        };
        let path = apiUrl.change_Language;
        const fr = await Helper.post(postJson, path, this.state.token);
        const res = await fr.response.json();
        if (fr.status === 200) {
            if (res.success) {
                console.log("res.result", res.result)
                Toast.fire({
                    type: "success",
                    title: res.msg,
                })
            } else {
                Toast.fire({
                    type: "error",
                    title: res.msg,
                });
            }
        } else {
            Toast.fire({
                type: "error",
                title: res.error,
            });

        }
    }


    handleSelect = address => {
        this.setState({ address: address })
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng =>
                this.setState({
                    location: {
                        lat: latLng.lat,
                        lon: latLng.lng
                    },
                    lat: latLng.lat,
                    lon: latLng.lng
                }, () => {
                    localStorage.setItem('location', JSON.stringify(this.state.location))
                }),
            )
            .catch(error => console.error('Error', error));
    };

    handleChange = address => {
        this.setState({
            address: address,
        });
    };


    render() {
        const headerClass = (params) => {
            switch (params) {
                case '/':
                case '/register':
                case '/home':
                    return '';
                default:
                    return 'inner-header';
            }
        }
        const arrowHidden = (goback) => {
            switch (goback) {
                case '/':
                // case '/register':
                case '/home':
                case '/login':
                    return 'arrowhidden';
                default:
                    return '';
            }
        }

        const addressHidden = (address) => {
            switch (address) {
                case '/':
                case '/register':
                case '/home':
                case '/login':
                    return 'd-none';
                default:
                    return 'locationHeaderuser';
            }
        }
        const { t } = this.props

        const searchOptions = {
            // location: new google.maps.LatLng(this.state.lat, this.state.lng),
            radius: 5000,
            // types: ['address']
        }
        return (
            <StickyHeader
                header={<header style={{ "background": "#FFF" }} className="navigation">
                    <div className="top-bar py-1">
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <div className="location d-flex align-items-center">



                                        <span className="location-tag d-flex align-items-center pr-1">
                                            <i className="fa fa-map-marker pr-2" aria-hidden="true"></i>
                                            Location: </span>
                                        <a href="javascript:void(0);" title={t("resd.Address")} onClick={(e) => {
                                            this.toggleAddress();
                                        }}>{this.state.location}</a>
                                    </div>
                                </div>
                                <div className="col-6">


                                    {/*<div className="d-flex justify-content-end">
                           <div className="currency d-flex align-items-center">
                              <span className="country-flag d-none d-sm-block  mr-1">
                              <img src={require("../../static/img/country.png").default} alt="country" />
                              </span>
                              <select className="custom-select border-0 p-0 bg-transparent">
                                 <option>US Dollar</option>
                                 <option>US Dollar</option>
                              </select>
                           </div>
                           <div className="language pl-1 pl-md-5">
                              <select className="custom-select border-0 p-0 bg-transparent">
                                 <option>English</option>
                                 <option>Hindi</option>
                              </select>
                           </div>
                        </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="navbar navbar-expand-md">
                        <div className="container position-relative">
                            <span className="Service-text">Convenient and efficient service</span>
                            <Link className="logo" to="/" title="Union">
                                <img src={require("../../static/img/logo.png").default} alt="speedy" />
                            </Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                                <span className="navbar-toggler-icon"></span>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse flex-wrap" id="navbarCollapse">
                                <ul id="navigation-menu" className="navbar-nav ml-auto">
                                    <li className="nav-item"><Link to="/how_it_works">How it Works </Link></li>
                                    <li className="nav-item"><Link to="/list_your_business">List your Business </Link></li>

                                    {this.isLogin !== null && <li className="nav-item">
                                        <Link to="/profile">{t("header.Profile")}</Link><span className="px-1">/ </span><a href="javascript:Void(0)" onClick={e => this.props.onLogout(e)}>{t("header.Logout")}</a>
                                    </li>}


                                    {this.isLogin === null && <li className="nav-item"><Link to="/login">Login</Link> <span className="px-1">/ </span><Link to="/register">Sign up</Link></li>}
                                    <li className="nav-item top-link-btn"><Link to="/searching" className="btn-order btn-blue btn"><img src={require("../../static/img/order.png").default} alt="order" className="ml-n2 mr-2" />Order Food</Link></li>
                                    <li className="nav-item top-link-btn"><a href="#" className="btn-order btn">Download App</a></li>

                                    {(localStorage.getItem('CartsItems')) ? ((JSON.parse(localStorage.getItem('CartsItems')).length > 0 && (localStorage.getItem('CartId'))) ? <li className="nav-item top-link-btn"><Link to={`/checkout/${localStorage.getItem('CartId')}`} className="btn-cart btn-blue btn"><i className="fa fa-shopping-cart ml-n2 mr-2"></i>{JSON.parse(localStorage.getItem('CartsItems')).length}</Link></li> : ((localStorage.getItem('CartsItems').length > 0 && (!localStorage.getItem('CartId'))) ? <li className="nav-item top-link-btn"><Link to={`/restaurant_details/${localStorage.getItem('restaurantId')}`} className="btn-cart btn-blue btn"><i className="fa fa-shopping-cart ml-n2 mr-2"></i>{JSON.parse(localStorage.getItem('CartsItems')).length}</Link></li> : '')) : ''}



                                </ul>
                            </div>
                        </div>
                    </nav>
                    {/* Modal */}
                    <div className={this.state.toggleAddress === false ? "modal Customization_modal fade locationmodule" : "modal Customization_modal fade locationmodule show modalOpenBox location-map-popup"} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg  modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">{t("resd.Address")}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                        this.setState({
                                            toggleAddress: false
                                        })
                                        window.location.reload()
                                    }}><span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body mapOuter addressOuterMap" >
                                    <PlacesAutocomplete
                                        searchOptions={{
                                            componentRestrictions:{
                                                country: ['ke'],
                                            }
                                        }}
                                        value={this.state.address}
                                        onChange={this.handleChange}
                                        onSelect={this.handleSelect} >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                <input
                                                    {...getInputProps({
                                                        placeholder: t('home.Search Your Places'),
                                                        className: 'location-search-input',
                                                    })}
                                                />
                                                <div className="autocomplete-dropdown-container">
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map(suggestion => {
                                                        const className = suggestion.active
                                                            ? 'suggestion-item--active'
                                                            : 'suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                    style,
                                                                })}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}

                                                </div>
                                                {/* <div className="centerBtn text-center">
                                                <button className="btn" onClick={(e) => {
                                                    if (this.state.address === '') {
                                                        this.setState({
                                                            isaddress: true
                                                        })
                                                    } else {
                                                        this.setState({
                                                            toggleAddress: false
                                                        })
                                                        window.location.reload()
                                                    }

                                                }}>{t("home.Submit")}</button>
                                            </div> */}
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                    <iframe src={`https://www.google.com/maps/embed/v1/place?key=${apiUrl.google_Api_Key}&q=${this.state.lat},${this.state.lon}`} width="600" height="450" frameBorder="0" style={{ border: 0 }} allowFullScreen=""></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.toggleAddress === true ? "modal-backdrop fade show" : ""} onClick={(e) => {
                        e.preventDefault();
                        this.setState({ toggleAddress: false })
                    }}></div>

                </header>}>
            </StickyHeader>
        )
    }
}
export default (mapStateToProps, mapDispatchToProps, withTranslation)(Header);