import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import apiUrl from '../../constants/apiPath';
import Helper from '../../constants/helper';
import Swal from 'sweetalert2';
import _ from "lodash";
import Loader from '../../views/Common/Loader';
import { withRouter } from 'react-router-dom';
import { setTranslations, setDefaultLanguage, withTranslation } from 'react-multi-lang';
import tr from '../MultLanguage/tr.json';
import en from '../MultLanguage/en.json';
import de from '../MultLanguage/de.json';
import es from '../MultLanguage/es.json';
import fr from '../MultLanguage/fr.json';
import lt from '../MultLanguage/lt.json';
import ru from '../MultLanguage/ru.json';

// Do this two lines only when setting up the application
let language = localStorage.getItem('Language') || 'en'
switch (localStorage.getItem('Language') || 'en') {
    case "tr":
        setTranslations({ tr })
        setDefaultLanguage(language.toString())
        break;
    case "fr":
        setTranslations({ fr })
        setDefaultLanguage(language.toString())
        break;
    case "es":
        setTranslations({ es })
        setDefaultLanguage(language.toString())
        break;
    case "ru":
        setTranslations({ ru })
        setDefaultLanguage(language.toString())
        break;
    case "lt":
        setTranslations({ lt })
        setDefaultLanguage(language.toString())
        break;
    case "de":
        setTranslations({ de })
        setDefaultLanguage(language.toString())
        break;
    default:
        setTranslations({ en })
        setDefaultLanguage('en')

}

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

class TableRestaurantlist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foodmanimg: require("../../static/img/food-man.png").default,
            restaurantList: [], weeksDay: '', visible: false, prerestaurantId: this.props.prerestaurantId,
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                900: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
                1200: {
                    items: 4,
                },
            },
            cartitems: localStorage.getItem('CartsItems') && JSON.parse(localStorage.getItem('CartsItems')) || [],
            lat: '', lon: '', customer_Currency_name: '',
            language: localStorage.getItem('Language') || 'en', userId: '', isreload: false, sourceCurrency: '', cust_Currency_Value: '',
            currencyArray: this.props.currencyArray,
        }
    }

    componentDidMount() {
        let lat = JSON.parse(localStorage.getItem('location')) && JSON.parse(localStorage.getItem('location')).lat || '';
        let lon = JSON.parse(localStorage.getItem('location')) && JSON.parse(localStorage.getItem('location')).lon || '';
        var userData = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            visible: true,
            lat: lat,
            lon: lon,
            customer_Currency_name: userData ? userData.default_currency : '',
            userId: userData ? userData.id : ''
        }, () => {
            this.getRestaurants();
        })

    }


    cartRemove = (curResturantsid, item) => {
        if (this.state.cartitems.length > 0) {
            if (this.state.prerestaurantId !== curResturantsid) {
                Swal.fire({
                    title: this.props.t('res.Cart Alert'),
                    text: this.props.t("res.Are you sure you want to clear cart"),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.props.t('res.Yes'),
                    cancelButtonText: this.props.t('res.No'),
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        localStorage.removeItem("CartsItems")
                        this.props.history.push('/restaurant_overview_details/' + `${curResturantsid}`);
                        Toast.fire({
                            type: "Success",
                            title: this.props.t("res.Successfully remove cart"),
                        })
                    }
                    else {
                        Toast.fire(
                            'Cancelled',
                            this.props.t("res.Your order is safe") + ':)',
                            'error'
                        )
                    }
                })
            } else {
                this.props.history.push('/restaurant_overview_details/' + `${this.state.prerestaurantId}`);
            }
        } else {
            this.props.history.push('/restaurant_overview_details/' + `${curResturantsid}`);
        }

        localStorage.setItem('ResturantsCurrency', item.default_currency ? item.default_currency : '')
        localStorage.setItem('Parentid', item.parent_id ? item.parent_id : '')
    }


    getRestaurants = async () => {
        this.setState({
            visible: true
        })
        let postJson = {
            language: this.state.language,
            localCountry: "60acf069460b9b30f486c230",
            visitorCountry: "60acf069460b9b30f486c230",
            nearByLng: this.state.lon.toString(),
            nearByLat: this.state.lat.toString(),
        };
        let path = apiUrl.get_Table_ResturantsList;
        const fr = await Helper.post(postJson, path);
        const res = await fr.response.json();
        if (fr.status === 200) {
            if (res.success) {
                this.setState({
                    restaurantList: res.result,
                    visible: false
                })
            } else {
                Toast.fire({
                    type: "error",
                    title: res.msg,
                });
                this.setState({
                    visible: false
                })
            }
        } else {
            this.setState({
                visible: false
            })
            Toast.fire({
                type: "error",
                title: res.error,
            });

        }
    }


    getCurrencycalculation = (rescurncyValue, rescurncyName) => {
        if (JSON.parse(localStorage.getItem('userData')) !== null) {
            loop1:
            for (var x = 0; x < this.state.currencyArray.length; x++) {
                var keys = Object.keys(this.state.currencyArray[x]);
                loop2:
                for (var i = 0; i < keys.length; i++) {
                    if (rescurncyName === keys[i]) {
                        if (rescurncyName !== this.state.customer_Currency_name) {
                            let value = Object.values(this.state.currencyArray[x])
                            let cust_Currency_Value = value[0]
                            localStorage.setItem('CurreancyValue', value[0])
                            if (1 < cust_Currency_Value) {
                                let value = rescurncyValue / cust_Currency_Value
                                return value.toFixed(2)
                            } else {
                                let value = rescurncyValue * cust_Currency_Value
                                return value.toFixed(2)
                            }

                        } else {
                            loop3:
                            for (var x = 0; x < this.state.currencyArray.length; x++) {
                                var keys = Object.keys(this.state.currencyArray[x]);
                                loop4:
                                for (var i = 0; i < keys.length; i++) {
                                    if ('USD' === keys[i]) {
                                        let value = Object.values(this.state.currencyArray[x])
                                        let cust_Currency_Value = value[0]
                                        localStorage.setItem('CurreancyValue', value[0])
                                        if (1 <= cust_Currency_Value) {
                                            let value = rescurncyValue / cust_Currency_Value
                                            return value.toFixed(2)
                                        } else {
                                            let value = rescurncyValue * cust_Currency_Value
                                            return value.toFixed(2)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        } else {
            loop1:
            for (var x = 0; x < this.state.currencyArray.length; x++) {
                var keys = Object.keys(this.state.currencyArray[x]);
                loop2:
                for (var i = 0; i < keys.length; i++) {
                    if (rescurncyName === keys[i]) {
                        let value = Object.values(this.state.currencyArray[x])
                        let cust_Currency_Value = value[0]
                        localStorage.setItem('CurreancyValue', value[0])
                        if (1 < cust_Currency_Value) {
                            let value = rescurncyValue / cust_Currency_Value
                            return value.toFixed(2)
                        } else {
                            let value = rescurncyValue * cust_Currency_Value
                            return value.toFixed(2)
                        }

                    }
                }
            }
        }

    }

    getCurrencyName = (rescurncyName) => {
        if (this.state.userId !== '') {
            if (rescurncyName === this.state.customer_Currency_name) {
                return 'USD'
            } else {
                return this.state.customer_Currency_name
            }
        } else {
            return 'USD'
        }

    }

    getWeekDay = (item) => {
        var today = new Date();
        var days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        var weekday = days[today.getDay() - 1]
        if (weekday === "Mon" && item.monday_open_time !== '' && item.monday_open_time) {
            return (this.props.t("tbl.Mon") + '-' + item.monday_open_time + ' ' + '-' + ' ' + item.monday_close_time)
        }
        if (weekday === "Tue" && item.tuesday_open_time !== '' && item.tuesday_open_time) {
            return (this.props.t("tbl.Tue") + '-' + item.tuesday_open_time + ' ' + '-' + ' ' + item.tuesday_close_time)
        }
        if (weekday === "Wed" && item.wednesday_open_time && item.wednesday_open_time !== '') {
            return (this.props.t("tbl.Wed") + '-' + item.wednesday_open_time + ' ' + '-' + ' ' + item.wednesday_close_time)
        }
        if (weekday === "Thu" && item.thursday_open_time && item.thursday_open_time !== '') {
            return (this.props.t("tbl.Thu") + '-' + item.thursday_open_time + ' ' + '-' + ' ' + item.thursday_close_time)
        }
        if (weekday === "Fri" && item.friday_open_time && item.friday_open_time !== '') {
            return (this.props.t("tbl.Fri") + '-' + item.friday_open_time + ' ' + '-' + ' ' + item.friday_close_time)
        }
        if (weekday === "Sat" && item.saturday_open_time && item.saturday_open_time !== '') {
            return (this.props.t("tbl.Sat") + '-' + item.saturday_open_time + ' ' + '-' + ' ' + item.saturday_close_time)
        }
        if (weekday === "Sun" && item.sunday_open_time && item.sunday_open_time !== '') {
            return (this.props.t("tbl.Sun") + '-' + item.sunday_open_time + ' ' + '-' + ' ' + item.sunday_close_time)
        }
    }

    getResLang = (reslang) => {
        switch (reslang) {
            case "English":
                localStorage.setItem('Reslanguage', 'en')
                return 'en'
        }
    }

    getResLang = (reslang) => {
        switch (reslang) {
            case "English":
                localStorage.setItem('Reslanguage', 'en')
                return 'en'
        }
    }

    render() {
        const { t } = this.props
        const { language } = this.state
        let tempSliderArray = [];
        let temp = [...this.state.restaurantList]
        while (temp.length) tempSliderArray.push(temp.splice(0, 2));
        return (
            <div>
                <Loader visible={this.state.visible} />
                <section className="food-collectiontbl">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {tempSliderArray.length > 0 && <div className="headings-outer">
                                    <h2>{t("tbl.Table Reservation Resturants Collection")}</h2>
                                </div>}
                            </div>

                            <div className="col-md-12">
                                {tempSliderArray.length > 0 &&
                                    <OwlCarousel className="owl-theme" margin={0} nav items={4} dots={false} responsive={this.state.responsive} id="food-category" >
                                        {tempSliderArray.length > 0 && tempSliderArray.map((items, index) => {
                                            let wrappedSliderItem = items.map(item => {
                                                return (
                                                    <div key={index, item._id} className={item.is_close === "yes" ? "food-outer tableresevrationOuter fooddisble" : "food-outer tableresevrationOuter"}>
                                                        <h3><img src={require("../../static/img/orange-right-vichlewhite.png").default} alt="#" className="img-fluid" /> {t("home.Table Reservation")}</h3>
                                                        <div className="food-img">
                                                            <Link to={{ pathname: '/table-reservation-details/' + `${item._id}` }}>
                                                                <img src={item.logo} onError={(e) => { e.target.onerror = null; e.target.src = require("../../static/img/logo.png").default }} alt="" />
                                                            </Link>
                                                        </div>
                                                        <div className="food-content">
                                                            <Link to={{ pathname: '/table-reservation-details/' + `${item._id}` }}>
                                                                {language === this.getResLang(item.local_language) ?
                                                                    <h5>{item.parent_name === '' ? item.name : item.parent_name + '(' + `${item.name}` + ')'}</h5> :
                                                                    <h5>{item.l_parent_name === '' ? item.l_name : item.l_parent_name + '(' + `${item.l_name}` + ')'}</h5>}
                                                                <p><img src={require("../../static/img/repet-1.png").default} alt="" />{language === this.getResLang(item.local_language) ? item.tag_line : item.tag_line_in_local}</p>
                                                            </Link>
                                                            <span className="star-reating">
                                                                <a href="javascript:void(0);"><i className="fas fa-star active">{Math.round(item.rating) + '.0'}</i></a>
                                                            </span>
                                                            <div className="date-time">
                                                                <p><span>{this.getWeekDay(item)}</span></p>
                                                                {item.is_close === 'no' ? <span className="open-now mt-2">
                                                                    ({t("res.Open Now")})
                                                                  </span> : <span className="open-now mt-2 text-danger">
                                                                        ({t("res.Close")})
                                                                  </span>}
                                                            </div>
                                                        </div>
                                                        <div className="restaurantAddress">
                                                            <p><i className="fas fa-map-marker-alt" /> {_.upperFirst(item.address_line_1) + (item.address_line_2 ? ', ' : '')}{_.upperFirst(item.address_line_2) + (item.address_line_2 ? ', ' : '')}</p>
                                                        </div>
                                                        <div className="costOuter orderPriceing">
                                                            <i className="fas fa-male" /><span>{t("tbl.Average cost for 1 person")}</span>
                                                            {item.min_order_price && <strong><span>{item.default_currency} {item.avg_order_amount}
                                                                <span> {this.getCurrencyName(item.default_currency)} {this.getCurrencycalculation(item.avg_order_amount, item.default_currency)}</span></span></strong>}
                                                        </div>
                                                        <div className="coupon-order">
                                                            <div className="order-now">
                                                                <Link to={{ pathname: '/table-reservation-details/' + `${item._id}` }} className="btn">{t("tbl.Reserve Table Now")}</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            return (
                                                <div className="item">{wrappedSliderItem}</div>)
                                        })}
                                    </OwlCarousel>}
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        );
    }
}


export default withRouter((withTranslation(TableRestaurantlist)));


