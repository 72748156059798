import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';
import './App.css';
import './static/css/bootstrap.min.css';
import './static/css/style.css';
import './static/css/media.css';
//import './static/fonts/fontawesome-5/css/all.css';
import { DefaultLayout } from './containers';
import More from './views/Pages/More/More';
import GoogleAnalytics from './views/GoogleAnalytics';
import ReactGA from 'react-ga';
import { googleAnalyticsID } from './constants/config';

class App extends Component {
  render() {
    ReactGA.initialize(googleAnalyticsID);
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
        <BrowserRouter>
            <Switch>
                {/* <Route path="/more/:link" name="More" component={More} /> */}
                <Route path="/" name="Default" component={DefaultLayout} />                
            </Switch>
            {/* <GoogleAnalytics/> */}
        </BrowserRouter>
    );
  }
}

export default App;
