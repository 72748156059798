let Config = {
    clientName: 'GapWallet',
    ReCAPTCHAKey: "6Lf0dHUUAAAAAJsYqglQjYRzlJBLvKD4beZgkLzd",
    googleClientID: "848570495015-q1okhuirqsla35fr78ouhjt3rit9gqp9.apps.googleusercontent.com",
    linkedinAppID: "81o20bc9qdxt6z",
    PLAID_PUBLICKEY: "266e14ba4ce11f3f3fa0cb8931a38e",
    PLAID_ENV: 'sandbox',
    PLAID_PRODUCT: ["auth", "transactions"],
    PLAID_SELECTACCOUNT: true,
    STRIPE_PUBLICKEY: "pk_live_fy28SOLH870y9tSZq7w9F48F",
    googleApiKey: "AIzaSyDh3B_Xre1AQHmejgCb36jZAXRAmLpX7lw",
    pubnubPublishKey: 'pub-c-9fa67acd-2a4e-489e-b1b3-c1e267213b8c',
    pubnubSubscribeKey: 'sub-c-b6a5e8de-c110-11ea-a44f-6e05387a1df4',
    //google_client_Id: "744023820192-dv2k9ar9bdksmetiatvtsi92fjudf4m5.apps.googleusercontent.com",
    google_client_Id: "1065737027849-ur189ncadn08382jhipj46rpmgvjni4e.apps.googleusercontent.com",
    facebookAppId: "366106364179599",
    social: {

        facebookLink: "https://www.facebook.com/GapWallet/",
        twitterLink: "https://twitter.com/mygapwallet",
        instagramLink: "https://www.instagram.com/mygapwallet/",
        linkedInLink: "https://www.linkedin.com/showcase/gapwallet/about/",
    },

    random_number: function (length) {

        var possiblenumber = "0123456789";
        var number = '';
        for (var i = 0; i < length; i++) {

            number += possiblenumber.charAt(Math.floor(Math.random() * possiblenumber.length));
        }
        return number;
    },
    random_string: function (length) {

        var possiblenumber = "abcdefghijklmnopqrstuwxyzABCDEFGHIJKLMNOPQRSTUWXYZ0123456789";
        var number = '';
        for (var i = 0; i < length; i++) {

            number += possiblenumber.charAt(Math.floor(Math.random() * possiblenumber.length));
        }
        return number;
    }
}
export default Config;
export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const googleAnalyticsID= process.env.REACT_APP_GA_ANALYTIC_ID;
