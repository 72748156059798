import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import apiUrl from '../../constants/apiPath';
import Helper from '../../constants/helper';
import Swal from 'sweetalert2';
import _ from "lodash";
import Loader from '../../views/Common/Loader';
import { withRouter } from 'react-router-dom';
import { setTranslations, setDefaultLanguage, withTranslation } from 'react-multi-lang';
import en from '../MultLanguage/en.json';

// Do this two lines only when setting up the application
let language = localStorage.getItem('Language') || 'en'


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

class Restaurantlist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foodmanimg: require("../../static/img/food-man.png").default,
            restaurantList: [], weeksDay: '', visible: false, prerestaurantId: this.props.prerestaurantId,
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                900: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
                1200: {
                    items: 4,
                },
            },
            cartitems: localStorage.getItem('CartsItems') && JSON.parse(localStorage.getItem('CartsItems')) || [],
            lat: '', lon: '', customer_Currency_name: '',
            language: localStorage.getItem('Language') || 'en', userId: '', isreload: false, sourceCurrency: '', cust_Currency_Value: ''
        }
    }

    componentDidMount() {
        let lat = JSON.parse(localStorage.getItem('location')) && JSON.parse(localStorage.getItem('location')).lat || '';
        let lon = JSON.parse(localStorage.getItem('location')) && JSON.parse(localStorage.getItem('location')).lon || '';
        var userData = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            visible: true,
            lat: lat,
            lon: lon,
            customer_Currency_name: userData ? userData.default_currency : '',
            userId: userData ? userData.id : ''
        }, () => {
            this.getRestaurants();
        })


    }


    cartRemove = (curResturantsid, item) => {
        if (this.state.cartitems.length > 0) {
            if (this.state.prerestaurantId !== curResturantsid) {
                Swal.fire({
                    title: this.props.t('res.Cart Alert'),
                    text: this.props.t("res.Are you sure you want to clear cart"),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.props.t('res.Yes'),
                    cancelButtonText: this.props.t('res.No'),
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        localStorage.removeItem("CartsItems")
                        this.props.history.push('/restaurant_details/' + `${curResturantsid}`);
                        Toast.fire({
                            type: "Success",
                            title: this.props.t("res.Successfully remove cart"),
                        })
                    }
                    else {
                        Toast.fire(
                            'Cancelled',
                            this.props.t("res.Your order is safe") + ':)',
                            'error'
                        )
                    }
                })
            } else {
                this.props.history.push('/restaurant_details/' + `${this.state.prerestaurantId}`);
            }
        } else {
            this.props.history.push('/restaurant_details/' + `${curResturantsid}`);
        }

        localStorage.setItem('ResturantsCurrency', item.default_currency ? item.default_currency : '')
        localStorage.setItem('Parentid', item.parent_id ? item.parent_id : '')
    }


    getRestaurants = async () => {
        this.setState({
            visible: true
        })
        let postJson = {
            language: this.state.language,
            nearByLng: this.state.lon.toString(),
            nearByLat: this.state.lat.toString(),
        };
        let path = apiUrl.get_Restaurants;
        const fr = await Helper.post(postJson, path);
        const res = await fr.response.json();
        if (fr.status === 200) {
            if (res.success) {
                this.setState({
                    restaurantList: res.result,
                    visible: false
                })
            } else {
                Toast.fire({
                    type: "error",
                    title: res.msg,
                });
                this.setState({
                    visible: false
                })
            }
        } else {
            this.setState({
                visible: false
            })
            Toast.fire({
                type: "error",
                title: res.error,
            });

        }
    }



    getWeekDay = (item) => {
        var today = new Date();
        var days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        var weekday = days[today.getDay() - 1]
        if (weekday === "Mon" && item.monday_open_time !== '' && item.monday_open_time) {
            return (this.props.t("tbl.Mon") + '-' + item.monday_open_time + ' ' + '-' + ' ' + item.monday_close_time)
        }
        if (weekday === "Tue" && item.tuesday_open_time !== '' && item.tuesday_open_time) {
            return (this.props.t("tbl.Tue") + '-' + item.tuesday_open_time + ' ' + '-' + ' ' + item.tuesday_close_time)
        }
        if (weekday === "Wed" && item.wednesday_open_time && item.wednesday_open_time !== '') {
            return (this.props.t("tbl.Wed") + '-' + item.wednesday_open_time + ' ' + '-' + ' ' + item.wednesday_close_time)
        }
        if (weekday === "Thu" && item.thursday_open_time && item.thursday_open_time !== '') {
            return (this.props.t("tbl.Thu") + '-' + item.thursday_open_time + ' ' + '-' + ' ' + item.thursday_close_time)
        }
        if (weekday === "Fri" && item.friday_open_time && item.friday_open_time !== '') {
            return (this.props.t("tbl.Fri") + '-' + item.friday_open_time + ' ' + '-' + ' ' + item.friday_close_time)
        }
        if (weekday === "Sat" && item.saturday_open_time && item.saturday_open_time !== '') {
            return (this.props.t("tbl.Sat") + '-' + item.saturday_open_time + ' ' + '-' + ' ' + item.saturday_close_time)
        }
        if (weekday === "Sun" && item.sunday_open_time && item.sunday_open_time !== '') {
            return (this.props.t("tbl.Sun") + '-' + item.sunday_open_time + ' ' + '-' + ' ' + item.sunday_close_time)
        }
    }

    getResLang = (reslang) => {
        switch (reslang) {
            case "English":
                localStorage.setItem('Reslanguage', 'en')
                return 'en'
        }
    }

    render() {
        const { t } = this.props
        const { language } = this.state
        let items = [];
        items = [...this.state.restaurantList]
        
        return (
            <>
            <Loader visible={this.state.visible} />
            <section className="features-sec">
                <div className="container">
                <div className="d-flex align-items-center pb-3 pb-md-4 mb-md-2">
                    <h2 className="sec-title mb-0 ">Featured <span className="red-text">Restaurants</span></h2>
                    <div className="ml-auto">
                        <Link to="/searching" className="view-all d-flex align-items-center justify-content-center">View all</Link>
                    </div>
                </div>
                <div className="row feature-restaurants">

                    {items.length > 0 && items.map((item, index) => {
    
                        return (<div  className="col-sm-6 col-md-4 col-lg-3 resItem"><a href="javascript:void(0);" onClick={(e) => {
                            this.cartRemove(item._id, item)
                        }}>
                            <div className="box-features feature-restaurants bg-white p-1">
                            <div className="features-img"><img src={item.photo} onError={(e) => { e.target.onerror = null; e.target.src = require("../../static/img/logo.png").default }} alt="features"/></div>
                            <div className="py-3 px-2">
                                <div className="d-flex">
                                    <ul className="d-flex rating-list">
                                        <li className="color-yellow mr-1"><i className="fa fa-star" aria-hidden="true"></i></li>
                                        <li className="color-yellow mr-1"><i className="fa fa-star" aria-hidden="true"></i></li>
                                        <li className="color-yellow mr-1"><i className="fa fa-star" aria-hidden="true"></i></li>
                                        <li className="color-yellow mr-1"><i className="fa fa-star" aria-hidden="true"></i></li>
                                        <li className="color-yellow mr-1"><i className="fa fa-star" aria-hidden="true"></i></li>
                                    </ul>
                                    
                                </div><div className="user-name ml-auto">{item.address_line_2}, {item.city}</div>
                                <div className="name pt-2 pt-md-4">
                                    <span className="d-block pb-1">{item.name}</span>
                                    <p className="m-0">{item.tag_line}</p>
                                </div>
                            </div>
                            </div></a>
                        </div>)

                    })}
                     </div>
               </div>
            </section>
                    </>
        );
    }
}


export default withRouter((withTranslation(Restaurantlist)));


